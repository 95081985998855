import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Projetos from '../views/Projetos.vue'
import Info from '../views/Info.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Login
  },
  {
    path: '/projetos',
    name: 'projetos',
    component: Projetos,
    meta: {
      login: true
    }
  },
  {
    path: '/info/:projectId',
    name: 'Info',
    component: Info,
    meta: {
      login: true
    }
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.login)) {
    if (!window.localStorage.token) {
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
