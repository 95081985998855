<template>
  <div>
    <div class="app-container">

      <div class="my-3">
        <div class="d-flex">
        <div class="_bg-primary d-flex align-items-center">
          <h1 class="m-0 text-white">
            Nome do Projeto: {{ project.pro_nome }}
          </h1>
        </div>
        <div class="before-Title"></div>
      </div>
      </div>

      <div class="d-flex">
        <div class="_bg-secund">
          <div><p class="m-0">Data de realização do projeto:</p></div>
          <div><p class="m-0">Início: {{ project.pro_datainicio }}</p></div>
          <div class="m-0"><p class="m-0">Fim: {{ project.pro_datafim }}</p></div>
        </div>
        <div class="before-Data"></div>
      </div>
    

      <div class="d-flex">
        <div class="_bg-equipe d-flex align-items-center">
          <h1 class="m-0 text-white">
            Equipe:
          </h1>
        </div>
        <div class="before-Equipe"></div>
      </div>

      <div>
        <ul>
          <li v-for="(items, i) in equipe" :key="i">{{items}}</li>
        </ul>
      </div>
      

        <div class="d-flex">
          <div class="_bg-dados d-flex align-items-center">
            <h1 class="text-white">
              Dados do projeto:
            </h1>
          </div>
          <div class="before-Dados"></div>
        </div>

        <div class="row">
          <div class="col descritivo">
            
            <div class="d-flex">
              <div class="_bg-descritivo d-flex align-items-center">
                <h1 class="m-0 text-white">
                  Descritivo
                </h1>
              </div>
              <div class="before-Descritivo"></div>
            </div>

            <p>
              {{ project.pro_descritivo }}
            </p>


          </div>
          <div class="col resultados">
            
            <div class="d-flex">
              <div class="_bg-resultados d-flex align-items-center">
                <h1 class="m-0 text-white">
                  Resultados
                </h1>
              </div>
              <div class="after-Resultados"></div>
            </div>

            <p>
             {{ project.pro_resultados }}
            </p>
            
            </div>
        </div>
      
        <div class="row mt-3 mb-5">
          <div class="col btn-footer">
            <router-link to="/projetos">
              <b-button pill class="d-block m-auto" size="lg">
                <b-icon icon="house-fill" aria-hidden="true"></b-icon>
                Voltar
              </b-button>
            </router-link>
          </div>
          <div class="col btn-footer">
            <b-button v-b-modal.modal pill class="d-block m-auto" size="lg">
              <b-icon icon="file-check-fill" aria-hidden="true"></b-icon>
              Votar
              </b-button>
          </div>
        </div>

    </div>
  <Modal />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from "vuex";
import Modal from '@/components/Projetos/Modal.vue'

export default {
  name: "Listagem",
  components:{
    Modal
  },
  data() {
    return {
      projectId: null,
      project: [],
    };
  },
  methods: {
    getProject() {
      this.listProjects.filter((item) => {
        item.id_projetos == this.projectId ? (this.project = item) : null;
      });
      localStorage.setItem('project', JSON.stringify(this.project));
    },
    verificarLogin(){
      const token = window.localStorage.token
      // token ? console.log(token) : console.log('Token Espirado')
      this.$store.commit('SET_KEY_LOGIN', token);
      let projectString = localStorage.getItem('project');
      this.project = JSON.parse(projectString);
    },
  },
  computed: {
    ...mapState(["listProjects"]),
    equipe(){
      return _.split(this.project.pro_equipe, ',')
    },
    getThisProject(){
      return localStorage.getItem('project');
    }
  },
  mounted() {
    this.projectId = this.$route.params.projectId;
    this.listProjects.length == 0 ? this.verificarLogin() : this.getProject()
  },
  created(){
    if(this.project.length > 0)
      this.$router.push('/projetos');
  }
};

</script>

<style lang="scss" scoped>

$cor1: #00A184;
$cor2: #FFFFFF;
$cor3: #F72717;
$cor4: #6D7787;

.app-container {
  max-width: 1200px;
  margin: auto;
  box-sizing: border-box;
  line-height: 1.62;

}
button{
  background: $cor1;
  border: none;
  &:hover{
    background:#026e5b;
  }
  &:focus{
    box-shadow: none;
    border: none;
  }
}

h1{
  padding-left: 15px;
  font-family: Edenred,sans-serif!important;
  font-weight: 400!important;
}
li{
  padding-left: 15px;
}
p{
  padding-left: 15px;
  font-family: gothicb;
  color: $cor4;
  span{
    padding-left: 15px;
  }
}
a{
  text-decoration: none;
}
////////////////////////////////////////////////// TITULO
._bg-primary {
  background: $cor1;
  height: 100%;
  padding: 10px 0 10px;

  border-radius: 0 100px 100px 0;
  min-width: 1000px;
  h1 {
    font-size: 25px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 23px;
    }
  }
  @media (max-width: 1200px) {
    // height: 10px;
    padding: 15px 0;
    min-width: 100%;
    max-width: 100%;
    border-radius: 0;
  }
  
}
.before-Title {
  position: relative;
  left: calc(-100vw - 1000px);
  min-width: 100vw;
  background: $cor1;
  @media (max-width: 1200px) {
    display: none;
  }
}

////////////////////////////////////////////////// DATA
._bg-secund {
  background: $cor3;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
  min-width: 1100px;
  border-radius: 0 30px 30px 0;
  margin-bottom: 15px;
  
  div {
    p{
      color: $cor2;
      font-size: 20px;
      font-weight: 700;
      font-family: Edenred,sans-serif!important;
      width: 340px;
      @media(max-width: 767px){
        font-size: 18px;
      }
    }
    width: 340px;
    margin-right: 70px;
  }
  @media (max-width: 1200px) {
    height: auto;
    padding: 10px 0;
    align-items: start;
    flex-direction: column;
    min-width: 100%;
    max-width: 100%;
    border-radius: 0;
    margin: 0;
    div{
      width: auto;
    }
  }
}

.before-Data {
  position: relative;
  top: 0px;
  left: calc(-100vw - 1100px);
  min-width: 100vw;
  height: 45px;
  background: $cor3;
  @media (max-width: 1200px) {
    display: none;
  }
}

////////////////////////////////////////////////// EQUIPE
._bg-equipe{
  background: $cor1;
  height: 50px;
  margin: 20px 0 0px;
  border-radius: 0 30px 30px 0;
  min-width: 1150px;
  h1 {
    font-size: 25px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 23px;
    }
  }
  @media (max-width: 1200px) {
    height: auto;
    padding: 15px 0;
    min-width: 100%;
    max-width: 100%;
    border-radius: 0;
  }
  
}
.before-Equipe{
   position: relative;
  top: 20px;
  left: calc(-100vw - 1150px);
  min-width: 100vw;
  height: 50px;
  background: $cor1;
  @media (max-width: 1200px) {
    display: none;
  }
}
////////////////////////////////////////////////// EQUIPE - PARTICIPANTES
ul {      
  position:relative;  
  margin:0 0 10px; 
  padding:20px 0;
  width:100%; 
  height:100%; 
   @media (max-width: 1200px) {
    margin:0;
  }
}
li {
  display:block; 
  list-style-type: none;
  margin:0; 
  box-sizing: border-box; 
  height:25%; 
  font-family: gothicb;
  color: $cor4;
}
li:nth-child(odd) { 
  clear:both; 
  float:left; 
  width:50%;
  
  @media (max-width: 1200px) {
    float:none;
  }
}
li:nth-child(even){
  position: relative;
  left: 50px;
  @media (max-width: 1200px) {
    left:0;
  }
}
////////////////////////////////////////////////// DADOS DO PROJETO
._bg-dados {
  background: $cor1;
  margin: 20px 0 10px;
  height: 50px;
  border-radius: 0 30px 30px 0;
  min-width: 280px;
  
  h1 {
    font-size: 25px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  @media (max-width: 1200px) {
    margin: 0px 0 10px;
    height: auto;
    padding: 15px 0;
    min-width: 100%;
    max-width: 100%;
    border-radius: 0;
  }
}
.before-Dados {
  position: relative;
  top: 20px;
  left: calc(-100vw - 280px);
  min-width: 100vw;
  height: 50px;
  background: $cor1;
  @media (max-width: 1200px) {
    display: none;
  }
}

////////////////////////////////////////////////// DESCRITIVO
.descritivo{
  max-width: 50%;
  p{
    padding-right: 40px;
    @media (max-width: 1200px) {
      padding-right: 0px;
    }
  }
  @media (max-width: 1200px) {
    min-width: 100%;
  }
}
._bg-descritivo {
  background: $cor3;
  height: 50px;
  margin: 20px 0 10px;
  border-radius: 0 30px 30px 0;
  min-width: 90%;
  h1 {
    font-size: 25px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 23px;
    }
  }
  @media (max-width: 1200px) {
    height: auto;
    padding: 15px 0;
    min-width: 100%;
    max-width: 100%;
    border-radius: 0;
  }
  
}
.before-Descritivo {
  position: relative;
  top: 20px;
  left: calc(-100vw - 90%);
  min-width: 100vw;
  height: 50px;
  background: $cor3;
  @media (max-width: 1200px) {
    display: none;
  }
}

////////////////////////////////////////////////// RESULTADOS
.resultados{
  max-width: 50%;
  width: 100%;
  
   @media (max-width: 1200px) {
    min-width: 100%;
  }
  p{
    padding-left: 40px;
    @media (max-width: 1200px) {
      padding-left: 15px;
    }
  }
}
._bg-resultados {
  background: $cor3;
  height: 50px;
  margin: 20px 0 10px;
  border-radius: 30px 0 0 30px;
  padding-left: 40px;
  min-width: 90%;
  h1 {
    font-size: 25px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 23px;
    }
  }
  @media (max-width: 1200px) {
    height: auto;
    padding: 15px 0;
    min-width: 100%;
    max-width: 100%;
    border-radius: 0;
  }
  
}
.after-Resultados {
  min-width: 100vw;
  position: relative;
  top: 20px;
  right: 0;
  height: 50px;
  background: $cor3;
  @media (max-width: 1200px) {
    display: none;
  }
}

.btn-footer{
  margin-top: 20px;
   @media (max-width: 1200px) {
    min-width: 100%;
  }
  button{
    width: 80%;
  }
}
</style>
