<template>
    <div>
        <Header />
        <InfoProjetos />
    </div>
</template>

<script>
import InfoProjetos from '@/components/Projetos/InfoProjetos.vue'
    export default {
        name: "Projetos",
        data(){
            return{
            }
        },
        components:{
            InfoProjetos
        },
        methods:{
            
        },
        computed:{
               
        },
        created(){
            
        }
    }
</script>

<style lang="scss" scoped>
div{
    // background: gold;
    overflow-x: hidden;
}
</style>