<template>
  <div>
    <Header/>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4">
          <Form/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from '@/components/Login/Form.vue';

  export default {
    name: 'Login',
    components: {
      Form
    },
    mounted(){
       const token = window.localStorage.token
       token ? this.$router.push('/projetos') : null
    }
  }
</script>


