<template>
  <div>
    <div class="container">
      <b-tabs content-class="my-3 pb-5" nav-class="myTabs">
        
        <b-tab title="EWARD 2021" title-item-class="myActiveTabs" title-link-class="My border-0">

          <b-list-group>
            <b-list-group-item button v-for="item in listProjects" :key='item.id_projetos' @click="setProject(item.id_projetos)" class="border-0 border-bottom rounded-0  d-flex justify-content-between align-items-center">
              <span class="text">{{item.pro_nome}}</span>
              <b-icon icon="circle" scale="1" variant="danger" v-if="!item.votos.criterio1"></b-icon>
              <b-icon icon="check-circle-fill" scale="1" style="color:#00A184" v-else></b-icon>
            </b-list-group-item>
          </b-list-group>

        </b-tab>

        <b-tab title="Ranking" title-item-class="myActiveTabs" title-link-class="My border-0">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Projetos</th>
                  <th class="text-center" v-for="(item, key) in jurados" :key="key">{{item}}</th>
                  <th class="text-center">Pontuação</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in ranking" :key="index">
                  <th>{{ item.pro_nome }}</th>
                  <td v-for="(votos, key) in item.votos" :key="key" class="text-center">
                    <span :id="`popover-${key}-${votos.total}-${votos.jurado}`"> {{votos.total}} </span>
                    <b-popover :target="`popover-${key}-${votos.total}-${votos.jurado}`" triggers="hover" placement="rightbottom">
                      <p class="m-0 criterio">
                        <span>
                          Criterio 1:
                          <span v-if="votos.criterios.criterio1 == 0"> Não se aplica </span>
                          <span v-if="votos.criterios.criterio1 == 1"> Não atende </span>
                          <span v-if="votos.criterios.criterio1 == 2"> Atende parcial </span>
                          <span v-if="votos.criterios.criterio1 == 3"> Atende </span>
                          <span v-if="votos.criterios.criterio1 == 4"> Supera </span>
                        </span>
                        <br>
                        <span>
                          Criterio 2:
                          <span v-if="votos.criterios.criterio2 == 0"> Não se aplica </span>
                          <span v-if="votos.criterios.criterio2 == 1"> Não atende </span>
                          <span v-if="votos.criterios.criterio2 == 2"> Atende parcial </span>
                          <span v-if="votos.criterios.criterio2 == 3"> Atende </span>
                          <span v-if="votos.criterios.criterio2 == 4"> Supera </span>
                        </span>
                        <br>
                        <span>
                          Criterio 3:
                          <span v-if="votos.criterios.criterio3 == 0"> Não se aplica </span>
                          <span v-if="votos.criterios.criterio3 == 1"> Não atende </span>
                          <span v-if="votos.criterios.criterio3 == 2"> Atende parcial </span>
                          <span v-if="votos.criterios.criterio3 == 3"> Atende </span>
                          <span v-if="votos.criterios.criterio3 == 4"> Supera </span>
                        </span>
                        <br>
                        <span>
                          Criterio 4:
                          <span v-if="votos.criterios.criterio4 == 0"> Não se aplica </span>
                          <span v-if="votos.criterios.criterio4 == 1"> Não atende </span>
                          <span v-if="votos.criterios.criterio4 == 2"> Atende parcial </span>
                          <span v-if="votos.criterios.criterio4 == 3"> Atende </span>
                          <span v-if="votos.criterios.criterio4 == 4"> Supera </span>
                        </span>
                        <br>
                        <span>
                          Criterio 5:
                          <span v-if="votos.criterios.criterio5 == 0"> Não se aplica </span>
                          <span v-if="votos.criterios.criterio5 == 1"> Não atende </span>
                          <span v-if="votos.criterios.criterio5 == 2"> Atende parcial </span>
                          <span v-if="votos.criterios.criterio5 == 3"> Atende </span>
                          <span v-if="votos.criterios.criterio5 == 4"> Supera </span>
                        </span>
                      </p>
                    </b-popover>
                  </td>
                  <td class="text-center">{{soma(item)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div></div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
  // import _ from 'lodash';
  import {
    api
  } from "@/services.js";
  import {
    mapState
  } from "vuex";

  export default {
    name: "Listagem",
    data() {
      return {
        ranking: [],
        teste: [
        {
          "votos": [
              {
                  "criterios": {
                      "criterio1": "5",
                      "criterio2": "5",
                      "criterio3": "5",
                      "criterio4": "5",
                      "criterio5": "5"
                  },
                  "total": 50,
                  "jurado": "JURADO TESTE",
                  "id": "25"
              },
              {
                  "criterios": {
                      "criterio1": "5",
                      "criterio2": "5",
                      "criterio3": "5",
                      "criterio4": "5",
                      "criterio5": "5"
                  },
                  "total": 60,
                  "jurado": "JURADO TESTE2",
                  "id": "26"
              },
              {
                  "criterios": {
                      "criterio1": "5",
                      "criterio2": "5",
                      "criterio3": "5",
                      "criterio4": "5",
                      "criterio5": "5"
                  },
                  "total": 50,
                  "jurado": "JURADO TESTE3",
                  "id": "27"
              }
          ],
          "id_projetos": "18",
          "pro_nome": "Ticket Car, muito além do abastecimento",
          "pontos": 25
        },
        {
          "votos": [
              {
                  "criterios": {
                      "criterio1": "5",
                      "criterio2": "5",
                      "criterio3": "5",
                      "criterio4": "5",
                      "criterio5": "5"
                  },
                  "total": 50,
                  "jurado": "JURADO TESTE",
                  "id": "25"
              },
              {
                  "criterios": {
                      "criterio1": "5",
                      "criterio2": "5",
                      "criterio3": "5",
                      "criterio4": "5",
                      "criterio5": "5"
                  },
                  "total": "aguardando",
                  "jurado": "JURADO TESTE2",
                  "id": "26"
              },
              {
                  "criterios": {
                      "criterio1": "5",
                      "criterio2": "5",
                      "criterio3": "5",
                      "criterio4": "5",
                      "criterio5": "5"
                  },
                  "total": 50,
                  "jurado": "JURADO TESTE3",
                  "id": "27"
              }
          ],
          "id_projetos": "18",
          "pro_nome": "Ticket Car, muito além do abastecimento",
          "pontos": 25
        }
        ],
        fields: [{
            key: 'id_projetos',
            label: 'Projetos'
          },
          {
            key: "votos",
            label: 'Jurados'
          },
          {
            key: 'pontos',
            label: 'Pontos'
          }
        ]
      }
    },
    computed: {
      ...mapState(["user", "keyLogin", "listGeral", "listEspecial", "listProjects"]),
      jurados() {
        let jurado = [];

        if (this.ranking.length > 0) {
          this.ranking.map((e) => {
            if (e.votos.length > 0) {
              e.votos.map(v => {
                jurado.push(v.jurado);
              })
            }
          })

          jurado = jurado.filter(function (item, pos) {
            return jurado.indexOf(item) == pos;
          })
          

          return jurado;
        }

        return null
      }
    },
    methods: {
      soma(v){
        let sum =  v.votos;
        let arr = [];
        sum.map(e => {
          let total = e.total;
          total = typeof total === "number" ? total : 0;
          arr.push(total);
        }) 

        const reducer = (accumulator, curr) => accumulator + curr;
        const total = arr.reduce(reducer);
        const div = (total / arr.length).toFixed(2)

        return div;
      },
      getRanking() {
        api.post("/", {
            keyAcess: "oIbfygBUibYcdbNfPsG64tPH80",
            getRanking: true,
            keyLogin: this.keyLogin
          })
          .then(response => {
            this.ranking = response.data.ranking;
          })
      },
      getProjects() {
        this.proEspecial = this.listEspecial
        this.proGeral = this.listGeral
      },
      setProject(id){
          let project = this.listProjects.filter((item) => {
            return item.id_projetos == id
          });

          this.$store.commit("SET_PROJECT", project[0])
          this.$router.push({ name: 'Info', params: { projectId: id } })
      }
    },
    created() {
      this.getProjects();
      this.getRanking();
    },
    filters: {
      FORMAT(v){
        return v.toFixed(2);
      }
    }
  };
</script>

<style lang="scss">
  $cor1: #00A184;
  $cor2: #FFFFFF;
  $cor3: #F72717;
  $cor4: #6D7787;

  a.nav-link {
    font-family: Montserrat Alternates;
    font-weight: 400;
    font-size: 23px;
    color: #949eaf;

    @media(max-width: 767px) {
      font-size: 18px;
    }

    &:hover {
      color: #00A184;
    }
  }

  .myTabs {
    border-bottom: 3px solid $cor1 !important;
  }

  .My.active {
    color: $cor4 !important;
    background: transparent !important;
    border: none !important;
    border-bottom: 3px solid rgb(255, 255, 255) !important;
    border-top: 3px solid $cor1 !important;
    border-right: 3px solid $cor1 !important;
    border-left: 3px solid $cor1 !important;
    border-radius: 20px 20px 0 0 !important;
    margin-bottom: -3px !important;
  }

  .text {
    font-family: EdenredRegular;
    font-size: 18px;
    font-weight: 400;
    color: $cor4;

    @media(max-width: 767px) {
      font-size: 15px;
    }
  }

  .table {

    td,
    tr,
    span {
      min-width: 100px;
      font-family: EdenredRegular;
      color: $cor4;
      font-size: 18px;

      @media(max-width: 767px) {
        font-size: 15px;
        min-width: 50px;
      }
    }

    .w-100 {
      width: 100%;
    }
  }

  .criterio {
    font-family: gothicb;
    color: $cor4;
  }
</style>